<template>
  <div>
    <v-app-bar
      id="home-app-bar"
      app
      color="white"
      elevation="1"
      height="90"
    >
      <base-img
        :src="require('@/assets/ImpotAD_logo.svg')"
        contain
        max-width="350"
        width="100%"
        style="margin-left: -30px"
      />

      <v-spacer />

      <div>
        <v-tabs
          class="hidden-sm-and-down"
          optional
        >
          <v-tab
            v-for="(name, i) in items"
            :key="i"
            :to="name.to"
            :ripple="false"
            active-class="text--primary"
            class="font-weight-bold app-bar-tabs"
            min-width="96"
            text
          >
            {{ name.title }}
          </v-tab>
        </v-tabs>
      </div>

      <v-app-bar-nav-icon
        class="hidden-md-and-up"
        @click="drawer = !drawer"
      />
    </v-app-bar>

    <home-drawer
      v-model="drawer"
      :items="items"
    />
  </div>
</template>

<script>
  export default {
    name: 'HomeAppBar',

    components: {
      HomeDrawer: () => import('./Drawer'),
    },

    data: () => ({
      drawer: null,
      items: [
        {
          title: 'Accueil',
          to: '/',
        },
        {
          title: 'À propos',
          to: 'about',
        },
        {
          title: 'Services',
          to: 'services',
        },
        {
          title: 'Aide-mémoire',
          to: 'aide-memoire',
        },
        {
          title: 'Questions fréquentes (FAQ)',
          to: 'faq',
        },
        {
          title: 'Contact',
          to: 'contact',
        },
      ],
    }),
  }
</script>

<style lang="sass">
  #home-app-bar
    .v-tabs-slider
      max-width: 24px
      margin: 0 auto

    .v-tab
      &::before
        display: none
</style>
